.nobreak {
  white-space: nowrap;
}
.nohyphens {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}
.hideme {
  display: none !important;
}
::-moz-selection {
  background: #004d9e;
  color: #fff;
}
::selection {
  background: #004d9e;
  color: #fff;
}
* {
  -webkit-tap-highlight-color: rgba(0, 77, 158, 0.1);
  box-sizing: border-box;
}
.page {
  background: #fff;
}
html {
  -webkit-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
body {
  letter-spacing: calc((0.9375rem + 0.1875
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 0.9375rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  color: #44515f;
  font-family: "Open Sans", sans-serif;
  -webkit-font-feature-settings: "liga", "kern";
          font-feature-settings: "liga", "kern";
  -webkit-font-kerning: normal;
          font-kerning: normal;
  font-smoothing: antialiased;
  font-weight: 300;
  hyphenate-limit-chars: 6 3 2;
  hyphenate-limit-lines: 2;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  line-height: 1.5;
  margin: 0;
  text-rendering: geometricPrecision;
}
@media (min-width: 20rem) {
  body {
    font-size: calc(0.9375rem + 0.1875
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75em) {
  body {
    font-size: 1.125rem;
  }
}
body.no_scroll {
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  word-wrap: break-word;
}
h1,
.styled_like_h1 {
  letter-spacing: calc((1.5rem + 0.875
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 1.5rem;
  color: #004d9e;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 300;
  line-height: 1.35;
  margin: 0 0 0.75em;
}
@media (min-width: 20rem) {
  h1,
  .styled_like_h1 {
    font-size: calc(1.5rem + 0.875
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75em) {
  h1,
  .styled_like_h1 {
    font-size: 2.375rem;
  }
}
h2 {
  letter-spacing: calc((1.375rem + 0.25
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 1.375rem;
  color: #44515f;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 300;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding-top: 0.75em;
}
@media (min-width: 20rem) {
  h2 {
    font-size: calc(1.375rem + 0.25
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75em) {
  h2 {
    font-size: 1.625rem;
  }
}
h3 {
  letter-spacing: calc((1.125rem + 0.125
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 1.125rem;
  color: #44515f;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 300;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding-top: 0.75em;
}
@media (min-width: 20rem) {
  h3 {
    font-size: calc(1.125rem + 0.125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75em) {
  h3 {
    font-size: 1.25rem;
  }
}
h4 {
  letter-spacing: calc((1.125rem + 0.125
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  color: #44515f;
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.35;
  margin: 0 0 0.75em;
}
@media (min-width: 20rem) {
  h4 {
    font-size: calc(1.125rem + 0.125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75em) {
  h4 {
    font-size: 1.25rem;
  }
}
h5 {
  letter-spacing: calc((1.125rem + 0.125
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 1.125rem;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 300;
  line-height: 1.35;
  margin: 0;
}
@media (min-width: 20rem) {
  h5 {
    font-size: calc(1.125rem + 0.125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75em) {
  h5 {
    font-size: 1.25rem;
  }
}
h6 {
  height: 0;
  margin: 0;
  overflow: hidden;
  width: 0;
}
p {
  margin: 0 0 0.75em;
}
a {
  color: #004d9e;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a:active,
a.active {
  color: #44515f;
  outline: 0;
}
a:focus {
  outline: 0.1875rem solid rgba(0, 77, 158, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a:focus[data-focus-method="mouse"],
a:focus[data-focus-method="touch"] {
  outline: 0;
}
a:hover {
  text-decoration: none;
}
a[tabindex="-1"]:focus {
  outline: 0;
}
a[tabindex="-1"][id] {
  position: relative;
  top: -2.5rem;
}
a[tabindex="-1"][id]:after {
  content: "";
}
a.touch_link {
  color: #44515f;
  display: block;
  text-decoration: none;
}
a.touch_link .title {
  color: #004d9e;
  display: inline-block;
  margin-bottom: 0.375em;
  outline-offset: 0;
  padding: 0;
  text-decoration: underline;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a.touch_link .title.active {
  color: #44515f;
}
a.touch_link:focus {
  outline: 0;
}
a.touch_link:focus .title {
  outline: 0.1875rem solid rgba(0, 77, 158, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a.touch_link:focus[data-focus-method="mouse"] .title,
a.touch_link:focus[data-focus-method="touch"] .title {
  outline: 0;
}
a.touch_link:hover .title {
  text-decoration: none;
}
.button {
  border-radius: 1.25rem;
  font-size: 1.125rem;
  padding: 0 3em;
  background: #004d9e;
  border: 0.0625rem solid #004d9e;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: background 300ms, border 300ms, color 300ms;
  transition: background 300ms, border 300ms, color 300ms;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.button > span {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  height: calc(3rem - (0.0625rem * 2));
  -webkit-box-pack: center;
          justify-content: center;
}
.button .icon {
  fill: #fff;
  height: 1.5rem;
  -webkit-transition: fill 300ms;
  transition: fill 300ms;
  width: 1.5rem;
}
.button .text {
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 125%;
  font-weight: 300;
  padding: 0.5em 0;
  white-space: nowrap;
}
.button .icon + .text {
  padding-left: 0.5em;
}
.button:focus {
  box-shadow: 0 0 0 0.1875rem rgba(0, 77, 158, 0.2);
  outline: 0;
  background: #0066d1;
  border-color: #0066d1;
  color: #fff;
}
.button:focus .icon {
  fill: #fff;
}
.button:hover {
  background: #0066d1;
  border-color: #0066d1;
  color: #fff;
}
.button:hover .icon {
  fill: #fff;
}
.button:active,
.button.active {
  box-shadow: 0 0 0.1875rem #004d9e inset;
  background: #004d9e;
  border: 0.0625rem solid #004d9e;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: background 300ms, border 300ms, color 300ms;
  transition: background 300ms, border 300ms, color 300ms;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.button:active > span,
.button.active > span {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  height: calc(3rem - (0.0625rem * 2));
  -webkit-box-pack: center;
          justify-content: center;
}
.button:active .icon,
.button.active .icon {
  fill: #fff;
  height: 1.5rem;
  -webkit-transition: fill 300ms;
  transition: fill 300ms;
  width: 1.5rem;
}
.button:active .text,
.button.active .text {
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 125%;
  font-weight: 300;
  padding: 0.5em 0;
  white-space: nowrap;
}
.button:active .icon + .text,
.button.active .icon + .text {
  padding-left: 0.5em;
}
.highlighted {
  -webkit-animation: highlighted_anchor 900ms;
          animation: highlighted_anchor 900ms;
}
strong {
  font-weight: 700;
}
em {
  font-style: normal;
}
blockquote {
  margin: 0 0 0.75em 1ch;
  text-indent: -1ch;
}
blockquote:before {
  content: "\201E";
}
blockquote:after {
  content: "\201C";
}
small {
  font-size: 85%;
}
sup {
  font-size: 85%;
  line-height: 1;
  position: relative;
  top: -0.3125rem;
}
sub {
  bottom: -0.125rem;
  font-size: 85%;
  line-height: 1;
  position: relative;
}
.table {
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1.5em;
  overflow-y: auto;
}
* + .table {
  margin-top: 1.5em;
}
table {
  border-bottom: 0.0625rem solid #c0d3e7;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  width: 100%;
}
th {
  font-weight: 700;
  text-align: left;
}
td,
th {
  border-top: 0.0625rem solid #c0d3e7;
  padding: 0.5em;
  -webkit-transition: background 300ms;
  transition: background 300ms;
  vertical-align: top;
}
ol {
  counter-reset: list;
  list-style: none;
  margin: 0 0 0.75em;
  padding: 0;
}
ol > li {
  padding-left: 1.5em;
  position: relative;
}
ol > li:before {
  color: #004d9e;
  content: counter(list) ".";
  counter-increment: list;
  font-weight: 700;
  left: 0;
  line-height: 1.5;
  position: absolute;
  text-align: right;
  width: 1.125em;
}
ol ol,
ol ul {
  margin: 0;
}
ul {
  list-style: none;
  margin: 0 0 0.75em;
}
ul > li {
  padding-left: 0.375em;
  position: relative;
}
ul > li::before {
  background: url("../images/symbol.svg");
  background-repeat: no-repeat;
  content: "";
  height: 0.875rem;
  margin-right: 0.375em;
  position: absolute;
  right: 100%;
  top: 0.5rem;
  width: 1.625rem;
}
ul ol,
ul ul {
  margin: 0;
}
.defined_list dl {
  border-bottom: 0.0625rem solid #c0d3e7;
  margin: 0.75em 0;
}
@media (min-width: 35em) {
  .defined_list dl {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
}
.defined_list dt {
  border-top: 0.0625rem solid #c0d3e7;
  font-weight: 700;
  padding: 0.75em 0;
}
@media (min-width: 35em) {
  .defined_list dt {
    -webkit-box-flex: 0;
            flex: 0 0 40%;
    max-width: 40%;
    padding: 0.75em 0.75em 0.75em 0;
  }
}
.defined_list dd {
  margin: 0;
}
@media (min-width: 35em) {
  .defined_list dd {
    border-top: 0.0625rem solid #c0d3e7;
    -webkit-box-flex: 0;
            flex: 0 0 60%;
    max-width: 60%;
    padding: 0.75em 0.5em 0 0;
  }
}
nav {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
nav ol {
  margin: 0;
}
nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
nav li {
  margin: 0;
  padding: 0;
}
nav li:before {
  display: none;
}
figure {
  margin: 0 0 1.5em;
}
figure img {
  display: block;
}
figure.center {
  margin-left: auto;
  margin-right: auto;
}
figure.floatleft {
  float: left;
  margin-right: 1.5em;
}
figure.floatright {
  float: right;
  margin-left: 1.5em;
}
figcaption {
  font-size: 85%;
  margin-top: 0.375em;
  text-align: left;
}
.img_container {
  overflow: hidden;
}
.img_container img,
.img_container svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.img_resize {
  height: 0;
  position: relative;
  width: 100%;
}
img {
  border: 0;
  display: inline-block;
  height: auto;
  max-width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
svg {
  height: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
  width: 100%;
}
svg a:focus {
  outline: 0;
}
input,
select,
textarea,
button {
  font-family: inherit;
  font-size: 1.125rem;
  font-weight: 300;
}
label {
  cursor: pointer;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #44515f;
  box-shadow: 0 0 0 62.5rem #fff inset, 0 0 0.1875rem #004d9e inset, 0 0 0 0.1875rem rgba(0, 77, 158, 0.2) !important;
}
input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
.form_disclaimer {
  background: #e5edf5;
  border-radius: 1.25rem;
  color: #44515f;
  font-size: 85%;
  margin-bottom: 0.75em;
  padding: 1em 1.5em;
}
::-webkit-input-placeholder {
  color: #7f91a3;
  -webkit-user-select: none;
          user-select: none;
}
:-ms-input-placeholder {
  color: #7f91a3;
  -ms-user-select: none;
      user-select: none;
}
::placeholder {
  color: #7f91a3;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.required_asterisk {
  color: #004d9e;
}
.required_description {
  font-size: 85%;
}
.error_warning {
  background: #004d9e;
  color: #fff;
  display: none;
  font-weight: 700;
  margin-right: 0.375em;
  padding: 0 0.375em;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.error_warning.show {
  display: inline-block;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.legend {
  letter-spacing: calc((1.25rem + 0.25
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 1.25rem;
  color: #44515f;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: 300;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding: 0.75em 0 0;
}
@media (min-width: 20rem) {
  .legend {
    font-size: calc(1.25rem + 0.25
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75em) {
  .legend {
    font-size: 1.5rem;
  }
}
.label {
  display: inline-block;
  margin-bottom: 0.375em;
}
.input,
.textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fff;
  border: 0.0625rem solid #c0d3e7;
  border-radius: 1.25rem;
  box-shadow: 0 0 0.1875rem #c0d3e7 inset;
  color: #44515f;
  margin: 0 0 1em;
  outline: 0;
  width: 100%;
}
.input[aria-invalid="true"],
.textarea[aria-invalid="true"] {
  border-color: #004d9e;
}
.input[aria-invalid="true"]:focus,
.textarea[aria-invalid="true"]:focus {
  border-color: #004d9e;
  box-shadow: 0 0 0.1875rem #004d9e inset, 0 0 0 0.1875rem rgba(0, 77, 158, 0.2);
  color: #44515f;
}
.input:focus,
.textarea:focus {
  border-color: #004d9e;
  box-shadow: 0 0 0.1875rem #004d9e inset, 0 0 0 0.1875rem rgba(0, 77, 158, 0.2);
  color: #44515f;
}
.input {
  height: 3rem;
  padding: 0 0.75em;
  -webkit-transition: border 300ms;
  transition: border 300ms;
}
.textarea {
  line-height: 1.5;
  overflow: hidden;
  padding: 0.75em;
  resize: none;
  -webkit-transition: border 300ms, height 300ms;
  transition: border 300ms, height 300ms;
}
.file {
  display: -webkit-box;
  display: flex;
  flex-basis: 65%;
  height: 3rem;
  margin: 0 0 1em;
  position: relative;
}
.file.error .file_name {
  border-color: #004d9e;
}
.file .file_name {
  background: #fff;
  border: 0.0625rem solid #c0d3e7;
  border-radius: 1.25rem 0 0 1.25rem;
  box-shadow: 0 0 0.1875rem #c0d3e7 inset;
  color: #44515f;
  -webkit-box-flex: 0;
          flex: 0 1 100%;
  height: 3rem;
  line-height: calc(3rem - (0.0625rem * 2));
  overflow: hidden;
  padding: 0 0.75em;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 8.125rem;
}
.file .file_button {
  border-radius: 0 1.25rem 1.25rem 0;
  -webkit-box-flex: 1;
          flex: 1 0 auto;
  padding: 0 1.5em;
  z-index: 10;
  background: #004d9e;
  border: 0.0625rem solid #004d9e;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: background 300ms, border 300ms, color 300ms;
  transition: background 300ms, border 300ms, color 300ms;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.file .file_button > span {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  height: calc(3rem - (0.0625rem * 2));
  -webkit-box-pack: center;
          justify-content: center;
}
.file .file_button .icon {
  fill: #fff;
  height: 1.5rem;
  -webkit-transition: fill 300ms;
  transition: fill 300ms;
  width: 1.5rem;
}
.file .file_button .text {
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 125%;
  font-weight: 300;
  padding: 0.5em 0;
  white-space: nowrap;
}
.file .file_button .icon + .text {
  padding-left: 0.5em;
}
.file .file_button.focus {
  box-shadow: 0 0 0 0.1875rem rgba(0, 77, 158, 0.2);
  background: #0066d1;
  border-color: #0066d1;
  color: #fff;
}
.file .file_button.focus .icon {
  fill: #fff;
}
.file [type="file"] {
  bottom: 0;
  color: transparent;
  cursor: pointer;
  display: block;
  height: 3rem;
  left: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 20;
}
.file [type="file"]:hover ~ .file_button {
  background: #0066d1;
  border-color: #0066d1;
  color: #fff;
}
.file [type="file"]:hover ~ .file_button .icon {
  fill: #fff;
}
.file [type="file"]:active ~ .file_button {
  box-shadow: 0 0 0.1875rem #004d9e inset;
  background: #004d9e;
  border: 0.0625rem solid #004d9e;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: background 300ms, border 300ms, color 300ms;
  transition: background 300ms, border 300ms, color 300ms;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.file [type="file"]:active ~ .file_button > span {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  height: calc(3rem - (0.0625rem * 2));
  -webkit-box-pack: center;
          justify-content: center;
}
.file [type="file"]:active ~ .file_button .icon {
  fill: #fff;
  height: 1.5rem;
  -webkit-transition: fill 300ms;
  transition: fill 300ms;
  width: 1.5rem;
}
.file [type="file"]:active ~ .file_button .text {
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 125%;
  font-weight: 300;
  padding: 0.5em 0;
  white-space: nowrap;
}
.file [type="file"]:active ~ .file_button .icon + .text {
  padding-left: 0.5em;
}
.control {
  -webkit-box-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: flex;
  margin: 0.375em 0.75em 0.75em 0;
}
.control.checkbox input:checked ~ .indicator .icon {
  opacity: 1;
  visibility: visible;
}
.control input {
  border: 0;
  cursor: pointer;
  height: 1.5rem;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 1.5rem;
  z-index: 10;
}
.control input[aria-invalid="true"] ~ .indicator {
  border-color: #004d9e;
}
.control input[aria-invalid="true"]:focus ~ .indicator {
  border-color: #004d9e;
  box-shadow: 0 0 0.1875rem #004d9e inset, 0 0 0 0.1875rem rgba(0, 77, 158, 0.2);
  color: #44515f;
}
.control input:focus ~ .indicator {
  border-color: #004d9e;
  box-shadow: 0 0 0.1875rem #004d9e inset, 0 0 0 0.1875rem rgba(0, 77, 158, 0.2);
  color: #44515f;
}
.control .indicator_wrapper {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  flex-shrink: 0;
  margin-right: 0.75em;
  position: relative;
}
.control .indicator {
  background: #fff;
  border: 0.0625rem solid #c0d3e7;
  border-radius: 1.25rem;
  height: 1.375rem;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 1.375rem;
}
.control .indicator .icon {
  fill: #44515f;
  height: 1.25rem;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
  visibility: hidden;
  width: 1.25rem;
}
.select_wrapper {
  display: block;
}
.select {
  background: #fff;
  border: 0.0625rem solid #c0d3e7;
  border-radius: 1.25rem;
  box-shadow: 0 0 0.1875rem #c0d3e7 inset;
  color: #44515f;
  cursor: pointer;
  display: block;
  margin: 0 0 1em;
  padding: 0 0.75em;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 100%;
}
.select:before {
  border: 0.625rem solid transparent;
  border-top-color: #44515f;
  content: "";
  display: inline-block;
  height: 0;
  margin-top: -0.3125rem;
  pointer-events: none;
  position: absolute;
  right: 0.75em;
  top: 50%;
  width: 0;
}
.select.error {
  border-color: #004d9e;
}
.select.error.focus {
  border-color: #004d9e;
  box-shadow: 0 0 0.1875rem #004d9e inset, 0 0 0 0.1875rem rgba(0, 77, 158, 0.2);
  color: #44515f;
}
.select.focus {
  border-color: #004d9e;
  box-shadow: 0 0 0.1875rem #004d9e inset, 0 0 0 0.1875rem rgba(0, 77, 158, 0.2);
  color: #44515f;
}
.select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  color: #44515f;
  cursor: pointer;
  height: calc(3rem - (0.0625rem * 2));
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.select select::-ms-expand {
  display: none;
}
.select select:focus:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #44515f;
}
.select select:focus::-ms-value {
  background: none;
  color: #44515f;
}
@media (min-width: 35em) {
  .formular .button_wrapper {
    text-align: right;
  }
}
button {
  cursor: pointer;
  height: 3rem;
  margin: 0.75em 0;
  outline: 0;
  overflow: hidden;
  padding: 0 1.5em;
  width: 100%;
}
@media (min-width: 35em) {
  button {
    width: auto;
  }
}
button::-moz-focus-inner {
  border: 0;
}
@-webkit-keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@-webkit-keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(0, 77, 158, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(0, 77, 158, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(0, 77, 158, 0.1);
    box-shadow: 0 0 0 0.625rem rgba(0, 77, 158, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@-webkit-keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -40%, 0);
            transform: translate3d(0, -40%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -40%, 0);
            transform: translate3d(0, -40%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
@-webkit-keyframes flipInX {
  from {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
    -webkit-transform: perspective(25rem) rotate3d(1, 0, 0, 90deg);
            transform: perspective(25rem) rotate3d(1, 0, 0, 90deg);
  }
  40% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-transform: perspective(25rem) rotate3d(1, 0, 0, -20deg);
            transform: perspective(25rem) rotate3d(1, 0, 0, -20deg);
  }
  60% {
    opacity: 1;
    -webkit-transform: perspective(25rem) rotate3d(1, 0, 0, 10deg);
            transform: perspective(25rem) rotate3d(1, 0, 0, 10deg);
  }
  80% {
    -webkit-transform: perspective(25rem) rotate3d(1, 0, 0, -5deg);
            transform: perspective(25rem) rotate3d(1, 0, 0, -5deg);
  }
  to {
    opacity: 1;
    -webkit-transform: perspective(25rem);
            transform: perspective(25rem);
  }
}
@keyframes flipInX {
  from {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
    -webkit-transform: perspective(25rem) rotate3d(1, 0, 0, 90deg);
            transform: perspective(25rem) rotate3d(1, 0, 0, 90deg);
  }
  40% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-transform: perspective(25rem) rotate3d(1, 0, 0, -20deg);
            transform: perspective(25rem) rotate3d(1, 0, 0, -20deg);
  }
  60% {
    opacity: 1;
    -webkit-transform: perspective(25rem) rotate3d(1, 0, 0, 10deg);
            transform: perspective(25rem) rotate3d(1, 0, 0, 10deg);
  }
  80% {
    -webkit-transform: perspective(25rem) rotate3d(1, 0, 0, -5deg);
            transform: perspective(25rem) rotate3d(1, 0, 0, -5deg);
  }
  to {
    opacity: 1;
    -webkit-transform: perspective(25rem);
            transform: perspective(25rem);
  }
}
@media screen {
  .page {
    min-width: 20rem;
  }
  .container {
    margin: 0 auto;
    max-width: 86.25rem;
    min-width: 20rem;
    padding: 0 0.9375rem;
  }
  .row {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    margin-left: -0.46875rem;
    margin-right: -0.46875rem;
  }
  .row.around_xsmall {
    justify-content: space-around;
  }
  .row.between_xsmall {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xsmall {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xsmall {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xsmall {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xsmall {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xsmall {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xsmall {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xsmall {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
  .row.no_column_margin {
    margin-left: 0;
    margin-right: 0;
  }
  .row.no_column_margin > [class*="span"] {
    padding-left: 0;
    padding-right: 0;
  }
  .row.half_column_margin {
    margin-left: -0.234375rem;
    margin-right: -0.234375rem;
  }
  .row.half_column_margin > [class*="span"] {
    padding-left: 0.234375rem;
    padding-right: 0.234375rem;
  }
  [class*="span"] {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
  }
  [class*="span"].span_column {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .span1_xsmall {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xsmall {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xsmall {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xsmall {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xsmall {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xsmall {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xsmall {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xsmall {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xsmall {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xsmall {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xsmall {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xsmall {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xsmall {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xsmall {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xsmall {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xsmall {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xsmall {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xsmall {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xsmall {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xsmall {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xsmall {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xsmall {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xsmall {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xsmall {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  .navigation_wrapper {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: 3.75rem;
    margin: 0 auto;
    max-width: 80rem;
    padding: 0 1.5em;
    position: relative;
    width: 100%;
  }
  .navigation_wrapper .icon_link .icon {
    display: block;
    fill: #b51b26;
    height: 2.25rem;
    margin: 0 0.75em;
    width: 2.25rem;
  }
  .image_wrapper {
    margin: 0 auto;
    max-width: 120rem;
    position: relative;
  }
  .image_wrapper .company_logo {
    display: block;
    max-width: 8.125rem;
    position: absolute;
    right: 1.5em;
    top: -1.5em;
    width: 100%;
    z-index: 10;
  }
  .image_wrapper .company_logo::after {
    background: -webkit-radial-gradient(center, ellipse, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.6) 24%, rgba(255, 255, 255, 0.5) 36%, rgba(255, 255, 255, 0) 68%, rgba(255, 255, 255, 0) 93%);
    background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.6) 24%, rgba(255, 255, 255, 0.5) 36%, rgba(255, 255, 255, 0) 68%, rgba(255, 255, 255, 0) 93%);
    bottom: -2.25em;
    content: "";
    left: -2.25em;
    position: absolute;
    right: -2.25em;
    top: -2.25em;
    z-index: -1;
  }
  .author_hor {
    display: inline-block;
    fill: #44515f;
    height: 0.5625rem;
    line-height: 0.5625rem;
    position: absolute;
    right: 0.3125rem;
    top: 0.3125rem;
    width: 4.6875rem;
  }
  .header_picture {
    position: relative;
    z-index: 5;
  }
  .header_picture img {
    display: block;
    margin: 0 auto;
  }
  .header_image img {
    display: block;
    margin: 0 auto;
  }
  .header_background {
    background-size: cover;
  }
  .slogan_wrapper {
    background: #004d9e;
    margin: 0 auto;
    max-width: 120rem;
    padding: 0.375em 0;
    z-index: 10;
  }
  .slogan_wrapper .slogan {
    color: #fff;
    margin: 0;
    opacity: 0;
    padding: 0;
    text-align: center;
    -webkit-animation: flipInX 1000ms;
            animation: flipInX 1000ms;
    -webkit-animation-delay: 800ms;
            animation-delay: 800ms;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }
  .welcome_text {
    background: #e5edf5;
    font-weight: 700;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    margin: 0 auto;
    max-width: 120rem;
    padding: 1.5em 0.75em;
    text-align: center;
    z-index: 0;
  }
  main {
    display: block;
    padding: 1.5em 0;
  }
  .main_wrapper {
    margin: 0 auto;
    margin-bottom: 1.5em;
    max-width: 120rem;
    min-height: 30em;
  }
  .main_wrapper .left_content {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .main_wrapper .right_content {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .breadcrumbs {
    display: none;
    flex-wrap: wrap;
    margin-bottom: 0.75em;
  }
  .breadcrumbs p {
    margin: 0 0.375em 0 0;
  }
  .breadcrumbs ol {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .breadcrumbs .separator {
    margin: 0 0.375em;
  }
  .contact_wrapper {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .contact_wrapper p {
    margin-right: 2.25em;
  }
  .contact_wrapper .address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact > span {
    padding-right: 0.75em;
  }
  .contact strong {
    min-width: 5.625rem;
  }
  .address_vcard_button {
    margin: 0 0 0.75em 0;
  }
  .address_call_button {
    margin: 0 0 0.75em 0;
  }
  .opening_times .title {
    padding-top: 0.75em;
  }
  .opening_times .entries {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .opening_times .entry {
    margin-right: 2.25em;
  }
  .download_overview {
    margin-bottom: 0.75em;
  }
  .download_overview .touch_link {
    display: -webkit-box;
    display: flex;
  }
  .download_overview .touch_link .title_wrapper {
    margin-bottom: 0.375em;
    padding-top: 0;
  }
  .download_overview .icon {
    flex-shrink: 0;
    height: 1.5rem;
    margin-right: 0.75em;
    width: 1.5rem;
  }
  .download_overview .icon_doc {
    fill: #2a5699;
  }
  .download_overview .icon_xls {
    fill: #4e892d;
  }
  .download_overview .icon_pdf {
    fill: #e44e4e;
  }
  .download_overview .icon_online {
    fill: #0074ce;
  }
  .onlinetools_popup_overview .entry {
    margin: 0 0 0.75em;
  }
  .onlinetools_popup_overview .description {
    display: block;
    margin-bottom: 0.75em;
  }
  .onlinetools_popup_overview .touch_link .title {
    margin: 0 0 0.375em;
    padding: 0;
  }
  .legal_notice_tax_aut_aab {
    margin-bottom: 0.75em;
    padding-top: 0.75em;
  }
  .legal_notice_tax_aut_aab [href$=".pdf"] {
    display: inline-block;
  }
  .legal_notice_tax_aut_aab [href$=".pdf"] > span {
    display: -webkit-box;
    display: flex;
  }
  .legal_notice_tax_aut_aab .icon {
    display: block;
    fill: #e44e4e;
    height: 1.5rem;
    margin-right: 0.6em;
    min-width: 1.5rem;
    width: 1.5rem;
  }
  .legal_notice_tax_aut {
    margin-bottom: 0.75em;
    padding-top: 0.75em;
  }
  .sub_menu {
    font-family: 'Open Sans Condensed', sans-serif;
    padding: 1.5em 0;
    position: relative;
    z-index: 10;
  }
  .sub_menu::before {
    background: #b51b26;
    bottom: 0;
    content: "";
    left: -50%;
    position: absolute;
    right: 0;
    top: 0;
    width: 200%;
    z-index: -1;
  }
  .sub_menu .overview_title {
    background: #fff;
    color: #b51b26;
    display: inline-block;
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 125%;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
  }
  .sub_menu ul {
    margin-bottom: 0.75em;
    margin-top: 0.75em;
    text-align: center;
  }
  .sub_menu ul li a {
    color: #fff;
    font-size: 125%;
    text-decoration: none;
  }
  .sub_menu ul li a:hover {
    text-decoration: underline;
  }
  .sub_menu ul li a.active {
    padding-left: 1.5em;
    position: relative;
  }
  .sub_menu ul li a.active::before {
    border-bottom: 0.625rem solid transparent;
    border-left: 0.875rem solid #fff;
    border-top: 0.625rem solid transparent;
    content: "";
    height: 0;
    left: 0;
    position: absolute;
    top: 0.375rem;
    width: 0;
  }
  .quote {
    background: #e5edf5;
    margin: 0 auto;
    max-width: 120rem;
    padding: 1.5em 0 3em;
  }
  .quote .title {
    color: #004d9e;
  }
  .quote .content {
    background: #fff;
    padding: 1.5em;
    position: relative;
  }
  .quote .content .name {
    display: block;
    font-style: italic;
    text-align: right;
  }
  .quote .content::before {
    background: url("../images/quote.svg");
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    height: 1.875rem;
    position: absolute;
    right: 0;
    top: -0.625rem;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    width: 1.875rem;
  }
  .quote .button_wrapper {
    display: block;
    margin: 1.5em 0 0;
    text-align: right;
  }
  .footer_wrapper {
    background: #004d9e;
    color: #fff;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    margin: 0 auto;
    max-width: 120rem;
  }
  .footer_wrapper a {
    color: #fff;
  }
  .footer_wrapper .title,
  .footer_wrapper .company_name {
    color: #fff;
  }
  .footer_wrapper .related_links {
    padding: 1.5em 0;
  }
  .footer_wrapper .related_links ul {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .footer_wrapper .related_links ul li {
    padding: 0 1.5em;
  }
  .footer_wrapper .contact_wrapper {
    -webkit-box-pack: end;
            justify-content: flex-end;
    position: relative;
    z-index: 2000;
  }
  .footer_wrapper .contact_wrapper p {
    font-size: 112.5%;
    margin: 0;
    text-align: right;
  }
  .footer_wrapper .contact_wrapper .button {
    margin-top: 1.5em;
    background: #fff;
    border: 0.0625rem solid #004d9e;
    color: #004d9e;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .footer_wrapper .contact_wrapper .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: calc(3rem - (0.0625rem * 2));
    -webkit-box-pack: center;
            justify-content: center;
  }
  .footer_wrapper .contact_wrapper .button .icon {
    fill: #004d9e;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .footer_wrapper .contact_wrapper .button .text {
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 125%;
    font-weight: 300;
    padding: 0.5em 0;
    white-space: nowrap;
  }
  .footer_wrapper .contact_wrapper .button .icon + .text {
    padding-left: 0.5em;
  }
  .footer_wrapper .contact_wrapper .button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(0, 77, 158, 0.2);
    outline: 0;
    background: #0066d1;
    border-color: #0066d1;
    color: #fff;
  }
  .footer_wrapper .contact_wrapper .button:focus .icon {
    fill: #fff;
  }
  .footer_wrapper .contact_wrapper .button:hover {
    background: #0066d1;
    border-color: #0066d1;
    color: #fff;
  }
  .footer_wrapper .contact_wrapper .button:hover .icon {
    fill: #fff;
  }
  .footer_wrapper .contact_wrapper .button:active,
  .footer_wrapper .contact_wrapper .button.active {
    box-shadow: 0 0 0.1875rem #004d9e inset;
    background: #004d9e;
    border: 0.0625rem solid #004d9e;
    color: #fff;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .footer_wrapper .contact_wrapper .button:active > span,
  .footer_wrapper .contact_wrapper .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: calc(3rem - (0.0625rem * 2));
    -webkit-box-pack: center;
            justify-content: center;
  }
  .footer_wrapper .contact_wrapper .button:active .icon,
  .footer_wrapper .contact_wrapper .button.active .icon {
    fill: #fff;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .footer_wrapper .contact_wrapper .button:active .text,
  .footer_wrapper .contact_wrapper .button.active .text {
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 125%;
    font-weight: 300;
    padding: 0.5em 0;
    white-space: nowrap;
  }
  .footer_wrapper .contact_wrapper .button:active .icon + .text,
  .footer_wrapper .contact_wrapper .button.active .icon + .text {
    padding-left: 0.5em;
  }
  .visible_for_screen_reader {
    border: 0;
    clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  .bypass_blocks {
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    z-index: 6000;
  }
  .bypass_blocks li {
    display: block;
    width: 20rem;
  }
  .bypass_blocks a {
    background: #fff;
    color: #004d9e;
    display: inline-block;
    font-size: 85%;
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0.375em;
    position: absolute;
    text-decoration: underline;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  .bypass_blocks a:focus {
    color: #004d9e;
    height: auto;
    left: 0.375em;
    top: 0.375em;
    width: auto;
  }
  abbr {
    text-decoration: none;
  }
  [role="tablist"] {
    margin-bottom: 1.5em;
  }
  * + [role="tablist"] {
    margin-top: 1.5em;
  }
  [role="tab"] {
    -webkit-box-align: center;
            align-items: center;
    border-top: 0.0625rem solid #c0d3e7;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 100%;
    margin: 0 0 0.375em;
    min-width: 100%;
    padding: 0.5em 0;
  }
  [role="tab"] .title {
    flex-basis: 100%;
    margin-right: 0.75em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  [role="tab"] .icon {
    display: block;
    fill: #004d9e;
    height: 1.5rem;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    width: 1.5rem;
  }
  [role="tab"][aria-expanded="true"] {
    border-color: #004d9e;
  }
  [role="tab"][aria-expanded="true"] .icon {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [role="tab"]:focus {
    border-color: #004d9e;
    outline: 0;
  }
  [role="tab"]:focus .title {
    color: #004d9e;
  }
  [role="tabpanel"] {
    height: auto;
    margin: 0;
    overflow: hidden;
  }
  [role="tabpanel"][aria-hidden="false"] {
    margin: 1.5em 0;
  }
  .csstransitions [role="tabpanel"].animated {
    -webkit-transition: margin 600ms, max-height 600ms;
    transition: margin 600ms, max-height 600ms;
  }
  .dropdown_menu {
    display: none;
  }
  .dropdown_menu > ul {
    display: -webkit-box;
    display: flex;
    position: relative;
  }
  .dropdown_menu > ul > li {
    -webkit-box-align: center;
            align-items: center;
  }
  .dropdown_menu > ul > li:hover > a,
  .dropdown_menu > ul > li.hover > a {
    color: #004d9e;
  }
  .dropdown_menu > ul > li:hover ul,
  .dropdown_menu > ul > li.hover ul {
    -webkit-transform: translateY(0em);
            transform: translateY(0em);
    visibility: visible;
  }
  .dropdown_menu > ul > li > a {
    -webkit-box-align: center;
            align-items: center;
    color: #b51b26;
    display: -webkit-box;
    display: flex;
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 125%;
    padding: 0.75em;
    text-decoration: none;
    white-space: nowrap;
  }
  .dropdown_menu > ul > li > a.active {
    color: #004d9e;
  }
  .dropdown_menu > ul > li ul {
    background: #f7fafc;
    padding: 0.75em 0;
    position: absolute;
    -webkit-transform: translateY(2em);
            transform: translateY(2em);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
    visibility: hidden;
    z-index: 1000;
  }
  .dropdown_menu > ul > li ul:target {
    visibility: visible;
  }
  .dropdown_menu > ul > li ul a {
    color: #44515f;
    display: block;
    padding: 0.1875em 1.5em;
    text-decoration: none;
    white-space: nowrap;
  }
  .dropdown_menu > ul > li ul a:hover {
    color: #004d9e;
  }
  .dropdown_menu > ul > li ul a:focus {
    outline-offset: -0.125rem;
  }
  .dropdown_menu > ul > li ul a.active {
    color: #004d9e;
  }
  .google_maps {
    margin: 0 0 0.75em;
  }
  .google_maps .map {
    background: #e5edf5;
    height: 50vh;
    min-height: 25rem;
  }
  .google_maps .map img {
    max-width: none;
  }
  .google_maps .map_print {
    display: none;
  }
  .google_maps .travel_mode {
    display: -webkit-box;
    display: flex;
    margin: 0 0 0.75em;
  }
  .google_maps .travel_mode input {
    opacity: 0;
    position: absolute;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio {
    z-index: 5;
    background: #c0d3e7;
    border-color: #aec7e0;
    color: #44515f;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio .icon {
    fill: #44515f;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio {
    box-shadow: 0 0 0 0.1875rem rgba(0, 77, 158, 0.2);
    z-index: 15;
    background: #0066d1;
    border-color: #004185;
    color: #fff;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio .icon {
    fill: #fff;
  }
  .google_maps .travel_mode_entry {
    flex-basis: 100%;
    height: 3rem;
  }
  .google_maps .travel_mode_radio {
    cursor: pointer;
    position: relative;
    width: 100%;
    background: #fff;
    border: 0.0625rem solid #c0d3e7;
    color: #44515f;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .google_maps .travel_mode_radio > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    height: calc(3rem - (0.0625rem * 2));
    -webkit-box-pack: center;
            justify-content: center;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #44515f;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .google_maps .travel_mode_radio .text {
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 125%;
    font-weight: 300;
    padding: 0.5em 0;
    white-space: nowrap;
  }
  .google_maps .travel_mode_radio .icon + .text {
    padding-left: 0.5em;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #44515f;
    min-width: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
  }
  .google_maps .travel_mode_radio.driving {
    border-radius: 1.25rem 0 0 1.25rem;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.transit,
  .google_maps .travel_mode_radio.bicycling {
    border-left-width: 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.walking {
    border-left-width: 0;
    border-radius: 0 1.25rem 1.25rem 0;
  }
  .google_maps .warnings {
    background: #f6ecec;
    border-radius: 1.25rem;
    color: #004d9e;
    font-size: 85%;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .google_maps .direction {
    margin: 0.75em 0;
  }
  .google_maps .button_wrapper {
    margin-top: auto;
  }
  .google_maps .panel_wrapper {
    padding-top: 0.75em;
  }
  .google_maps .steps {
    min-width: 100%;
  }
  .google_maps .step {
    border-top: 0.0625rem solid #c0d3e7;
    display: -webkit-box;
    display: flex;
  }
  .google_maps .step.last {
    border-bottom: 0.0625rem solid #c0d3e7;
  }
  .google_maps .step:before {
    font-size: 85%;
    padding: 0.75em 0;
  }
  .google_maps .instructions,
  .google_maps .distance {
    font-size: 85%;
    padding: 0.75em 0;
  }
  .google_maps .instructions {
    overflow: hidden;
    padding-left: 0.75em;
    width: 100%;
    word-wrap: break-word;
  }
  .google_maps .distance {
    min-width: 5rem;
    text-align: right;
  }
  .image_gallery_overview {
    margin-bottom: 1.5em;
    position: relative;
  }
  .image_gallery_entry {
    background-color: #e5edf5;
    border-bottom: 0.1875rem solid #fff;
    border-right: 0.1875rem solid #fff;
    display: block;
    position: relative;
  }
  .image_gallery_entry:focus,
  .image_gallery_entry:hover {
    outline: 0;
  }
  .image_gallery_entry:focus .open,
  .image_gallery_entry:hover .open {
    opacity: 1;
    visibility: visible;
  }
  .image_gallery_entry:focus .open svg,
  .image_gallery_entry:hover .open svg {
    -webkit-animation: news_photo 900ms;
            animation: news_photo 900ms;
  }
  .image_gallery_entry .open {
    background: rgba(68, 81, 95, 0.6);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    fill: #fff;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: all 600ms;
    transition: all 600ms;
    visibility: hidden;
  }
  .image_gallery_entry .open svg {
    height: 3.75rem;
    margin: auto;
    width: 3.75rem;
  }
  .image_gallery_entry .image {
    background-position: 50%;
    background-size: cover;
    height: 0;
    overflow: hidden;
    padding-bottom: 62.5%;
  }
  .image_gallery_overlay {
    background: #44515f;
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    width: 100%;
    z-index: 9999;
  }
  .image_gallery_overlay.show {
    opacity: 1;
    visibility: visible;
  }
  .image_gallery_overlay .slider {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transition: -webkit-transform 300ms ease;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease;
    white-space: nowrap;
    width: 100%;
  }
  .image_gallery_overlay .slider.bounce_from_right {
    -webkit-animation: image_gallery_bounce_from_right 300ms ease-out;
            animation: image_gallery_bounce_from_right 300ms ease-out;
  }
  .image_gallery_overlay .slider.bounce_from_left {
    -webkit-animation: image_gallery_bounce_from_left 300ms ease-out;
            animation: image_gallery_bounce_from_left 300ms ease-out;
  }
  .image_gallery_overlay .full_image {
    display: inline-block;
    height: 100%;
    position: relative;
    text-align: center;
    width: 100%;
  }
  .image_gallery_overlay .full_image:before {
    content: "";
    display: inline-block;
    height: 50%;
    width: 0;
  }
  .image_gallery_overlay .full_image figure {
    display: inline;
    height: 100%;
    margin: 0;
  }
  .image_gallery_overlay .full_image figcaption {
    background: rgba(68, 81, 95, 0.6);
    bottom: 0;
    color: #fff;
    display: block;
    padding: 0.75em;
    position: absolute;
    text-align: center;
    white-space: normal;
    width: 100%;
  }
  .image_gallery_overlay .full_image img {
    display: inline-block;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    opacity: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    vertical-align: middle;
    visibility: hidden;
    width: auto;
  }
  .image_gallery_overlay .full_image img.show {
    opacity: 1;
    visibility: visible;
  }
  .image_gallery_overlay .spinner {
    display: inline-block;
    height: 1.125rem;
    left: 50%;
    margin-left: -2.1875rem;
    margin-top: -0.5625rem;
    position: absolute;
    top: 50%;
    width: 4.375rem;
  }
  .image_gallery_overlay .spinner .bounce {
    -webkit-animation: image_gallery_spinner 900ms infinite;
            animation: image_gallery_spinner 900ms infinite;
    background: #004d9e;
    border-radius: 100%;
    display: inline-block;
    height: 1.125rem;
    margin: 0 0.25rem;
    width: 1.125rem;
  }
  .image_gallery_overlay .spinner .bounce_1 {
    -webkit-animation-delay: -300ms;
            animation-delay: -300ms;
  }
  .image_gallery_overlay .spinner .bounce_2 {
    -webkit-animation-delay: -150ms;
            animation-delay: -150ms;
  }
  .image_gallery_overlay nav a {
    cursor: pointer;
    display: block;
    position: absolute;
  }
  .image_gallery_overlay nav .previous,
  .image_gallery_overlay nav .next {
    height: 2.75rem;
    margin-top: -1.375rem;
    top: 50%;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 2.75rem;
  }
  .image_gallery_overlay nav .previous:focus .icon,
  .image_gallery_overlay nav .next:focus .icon,
  .image_gallery_overlay nav .previous:hover .icon,
  .image_gallery_overlay nav .next:hover .icon {
    fill: #004d9e;
  }
  .image_gallery_overlay nav .previous .icon,
  .image_gallery_overlay nav .next .icon {
    fill: #fff;
    height: 2.75rem;
    width: 2.75rem;
  }
  .image_gallery_overlay nav .previous {
    left: 2%;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .image_gallery_overlay nav .next {
    right: 2%;
  }
  .image_gallery_overlay nav .close {
    background: #fff;
    height: 2.75rem;
    padding: 0.375rem;
    right: 0;
    top: 0;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 2.75rem;
  }
  .image_gallery_overlay nav .close:focus,
  .image_gallery_overlay nav .close:hover {
    outline: 0;
  }
  .image_gallery_overlay nav .close:focus .icon,
  .image_gallery_overlay nav .close:hover .icon {
    fill: #004d9e;
  }
  .image_gallery_overlay nav .close .icon {
    fill: #44515f;
    height: 2rem;
    width: 2rem;
  }
  .toggle_offcanvas_menu {
    display: inline-block;
    position: relative;
  }
  .toggle_offcanvas_menu label {
    -webkit-box-align: center;
            align-items: center;
    color: #b51b26;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 150%;
    font-weight: 300;
    height: 3rem;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 10;
  }
  .toggle_offcanvas_menu label .icon {
    height: 1.875rem;
    margin-right: 0.375em;
    position: relative;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: -webkit-transform 350ms 150ms;
    transition: -webkit-transform 350ms 150ms;
    transition: transform 350ms 150ms;
    transition: transform 350ms 150ms, -webkit-transform 350ms 150ms;
    width: 2.125rem;
  }
  .toggle_offcanvas_menu label .line {
    background: #b51b26;
    display: block;
    height: 0.25rem;
    position: absolute;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 100%;
  }
  .toggle_offcanvas_menu label .line_1 {
    opacity: 1;
    top: 0.3125rem;
    -webkit-transition: background 300ms, top 300ms 300ms, -webkit-transform 300ms;
    transition: background 300ms, top 300ms 300ms, -webkit-transform 300ms;
    transition: background 300ms, top 300ms 300ms, transform 300ms;
    transition: background 300ms, top 300ms 300ms, transform 300ms, -webkit-transform 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_2 {
    opacity: 1;
    top: 0.8125rem;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_3 {
    bottom: 0.3125rem;
    -webkit-transition: background 300ms, bottom 300ms 300ms, -webkit-transform 300ms;
    transition: background 300ms, bottom 300ms 300ms, -webkit-transform 300ms;
    transition: background 300ms, bottom 300ms 300ms, transform 300ms;
    transition: background 300ms, bottom 300ms 300ms, transform 300ms, -webkit-transform 300ms;
  }
  .toggle_offcanvas_menu label .text {
    display: none;
  }
  .toggle_offcanvas_menu a {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .toggle_offcanvas_menu .open_offcanvas_menu {
    display: block;
  }
  .toggle_offcanvas_menu .close_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line_1 {
    top: 0.8125rem;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: background 300ms, top 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, top 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, top 300ms, transform 300ms 300ms;
    transition: background 300ms, top 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line_2 {
    opacity: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line_3 {
    bottom: 0.8125rem;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: background 300ms, bottom 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, bottom 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, bottom 300ms, transform 300ms 300ms;
    transition: background 300ms, bottom 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu .open_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu .close_offcanvas_menu {
    display: block;
  }
  .offcanvas_menu_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
    min-height: 100vh;
    min-width: 20rem;
    overflow-x: hidden;
    position: relative;
    width: 100%;
    z-index: 5000;
  }
  .offcanvas_menu_wrapper .page {
    -webkit-box-flex: 1;
            flex: 1 0 100%;
    min-height: 100vh;
    position: relative;
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .offcanvas_menu_wrapper .offcanvas_menu {
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .offcanvas_menu_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(68, 81, 95, 0.45);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
    z-index: 110;
  }
  .offcanvas_menu {
    background: #fff;
    -webkit-box-flex: 1;
            flex: 1 0 15.625rem;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    opacity: 0;
    padding: 1.5em 0;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li {
    border-bottom: 0.0625rem solid #e5edf5;
    padding: 0 1.5em;
    position: relative;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li.expanded > a ~ ul {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    color: #b51b26;
    display: block;
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 136%;
    line-height: 3rem;
    text-decoration: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a.active {
    color: #004d9e;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul:target {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a {
    color: #44515f;
    display: block;
    padding: 0.375em 1.5em 0.375em 0.375em;
    text-decoration: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a.active {
    color: #004d9e;
  }
  .popup_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(68, 81, 95, 0.45);
    bottom: 0;
    left: 0;
    opacity: 0;
    padding: 1% 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
    z-index: -1;
  }
  .popup_overlay.show {
    opacity: 1;
    visibility: visible;
    z-index: 9000;
  }
  .popup_header {
    padding: 0 0 0 1.5em;
  }
  .popup_close {
    background: #004d9e;
    border-radius: 1.25rem 1.25rem 0 0;
    float: right;
    height: 2.4rem;
    margin-right: 0.0625rem;
    padding: 0.42857143em;
    text-align: center;
    width: 3rem;
  }
  .popup_close .icon {
    fill: #fff;
    height: 1.5rem;
    width: 1.5rem;
  }
  .popup_content {
    height: 100%;
    margin: 0 auto;
    max-width: 86.25rem;
    opacity: 0;
    position: relative;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
    z-index: 9500;
  }
  .popup_content.show {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    visibility: visible;
  }
  .popup_content .content {
    background: #fff;
    bottom: 0;
    box-shadow: 0 0 1.5625rem rgba(68, 81, 95, 0.5);
    left: 0;
    position: absolute;
    right: 0;
    top: 2.4rem;
  }
  .popup_iframe {
    background: #fff;
    border: 0;
    height: 100%;
    opacity: 0;
    position: relative;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 100%;
    z-index: 1;
  }
  .popup_iframe.show {
    opacity: 1;
  }
  .preloader {
    display: block;
    position: relative;
  }
  .preloader .icon_spin {
    -webkit-animation: preloader_rotate 900ms linear infinite;
            animation: preloader_rotate 900ms linear infinite;
    display: block;
    fill: #c0d3e7;
    height: 3rem;
    left: 50%;
    margin: -1.5rem 0 0 -1.5rem;
    opacity: 1;
    position: absolute;
    top: 50%;
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: visible;
    width: 3rem;
  }
  .preloader .icon_spin.hide {
    opacity: 0;
    visibility: hidden;
  }
  .no-cssanimations .icon_spin {
    display: none;
  }
  .teaser_overview .teaser {
    margin-bottom: 1.5em;
    width: 100%;
  }
  .teaser_overview .teaser .title {
    letter-spacing: calc((1.375rem + 0.125
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
    font-size: 1.375rem;
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: center;
            justify-content: center;
    text-align: center;
    width: 100%;
  }
  .teaser_overview .icon {
    display: block;
    height: 3rem;
    margin-bottom: 1.5em;
    width: 100%;
  }
  .startpage .teaser_overview {
    padding: 3em 0;
  }
  .team_overview .entry {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
    margin-bottom: 0.75em;
  }
  .team_overview .title {
    margin: 0;
    padding: 0;
  }
  .team_overview .photo {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0 1.5em 0.75em 0;
    max-width: 8.75rem;
  }
  .team_overview .photo img {
    display: block;
  }
  .team_overview .info {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    margin-bottom: 0.75em;
  }
  .team_overview .button {
    margin-bottom: 0.75em;
  }
  .team_entry .photo {
    margin-bottom: 0.75em;
    max-width: 8.75rem;
  }
  .team_entry .photo img {
    display: block;
  }
  .team_entry .info {
    margin-bottom: 0.75em;
  }
  .tooltip_wrapper {
    display: block;
    position: relative;
  }
  [role="tooltip"] {
    background: #e5edf5;
    border: 0.0625rem solid #c0d3e7;
    border-radius: 1.25rem;
    color: #44515f;
    font-size: 85%;
    font-weight: 300;
    line-height: 2.0625rem;
    padding: 0 0.75em;
    position: absolute;
    right: 0;
    text-align: center;
    top: -2.4375rem;
    z-index: 50;
  }
  [role="tooltip"]:after {
    border: 0.625rem solid transparent;
    border-top-color: #e5edf5;
    bottom: -1.25rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.625rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"]:before {
    border: 0.6875rem solid transparent;
    border-top-color: #c0d3e7;
    bottom: -1.375rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.6875rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"][aria-hidden="true"] {
    display: none;
  }
}
@media screen and (min-width: 20rem) {
  .container {
    padding: 0 calc(0.9375rem + 2.1875
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  .container {
    padding: 0 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row {
    margin: 0 calc((-0.9375rem / 2) - (2.1875 / 2)
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  .row {
    margin: 0 -1.5625rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin {
    margin: 0 calc((-0.9375rem / 4) - (2.1875 / 4)
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  .row.half_column_margin {
    margin: 0 -0.78125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 calc((0.9375rem / 4) + (2.1875 / 4)
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 0.78125rem;
  }
}
@media screen and (min-width: 30em) {
  .row.around_small {
    justify-content: space-around;
  }
  .row.between_small {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_small {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_small {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_small {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_small {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_small {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_small {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_small {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 35em) {
  .row.around_medium {
    justify-content: space-around;
  }
  .row.between_medium {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_medium {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_medium {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_medium {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_medium {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_medium {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_medium {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_medium {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 47.5em) {
  .row.around_large {
    justify-content: space-around;
  }
  .row.between_large {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_large {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_large {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_large {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_large {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_large {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_large {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_large {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 61.875em) {
  .row.around_xlarge {
    justify-content: space-around;
  }
  .row.between_xlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 75em) {
  .row.around_xxlarge {
    justify-content: space-around;
  }
  .row.between_xxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 93.75em) {
  .row.around_xxxlarge {
    justify-content: space-around;
  }
  .row.between_xxxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 20rem) {
  [class*="span"] {
    padding: 0 calc((0.9375rem / 2) + (2.1875 / 2)
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  [class*="span"] {
    padding: 0 1.5625rem;
  }
}
@media screen and (min-width: 30em) {
  .span1_small {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_small {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_small {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_small {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_small {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_small {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_small {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_small {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_small {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_small {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_small {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_small {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_small {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_small {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_small {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_small {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_small {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_small {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_small {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_small {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_small {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_small {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_small {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_small {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 35em) {
  .span1_medium {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_medium {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_medium {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_medium {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_medium {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_medium {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_medium {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_medium {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_medium {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_medium {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_medium {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_medium {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_medium {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_medium {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_medium {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_medium {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_medium {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_medium {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_medium {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_medium {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_medium {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_medium {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_medium {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_medium {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 47.5em) {
  .span1_large {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_large {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_large {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_large {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_large {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_large {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_large {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_large {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_large {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_large {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_large {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_large {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_large {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_large {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_large {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_large {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_large {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_large {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_large {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_large {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_large {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_large {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_large {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_large {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 61.875em) {
  .span1_xlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 75em) {
  .span1_xxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 93.75em) {
  .span1_xxxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 61.875em) {
  .navigation_wrapper .icon_link {
    display: none;
  }
}
@media screen and (min-width: 61.875em) {
  .navigation_wrapper {
    height: 5rem;
  }
}
@media screen and (min-width: 35em) {
  .image_wrapper .company_logo {
    max-width: 12.5rem;
  }
}
@media screen and (min-width: 75em) {
  .image_wrapper .company_logo {
    max-width: 18.75rem;
    right: 10%;
    top: -2.25em;
  }
}
@media screen and (min-width: 35em) {
  .header_picture img {
    border-top-left-radius: 100% 10%;
    border-top-right-radius: 0 0;
    position: relative;
  }
}
@media screen and (min-width: 35em) {
  .slogan_wrapper {
    position: relative;
  }
  .slogan_wrapper::before {
    background: url("../images/bogen_oben.svg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: auto auto;
    bottom: 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    -webkit-transform: translateY(-99%);
            transform: translateY(-99%);
  }
  .slogan_wrapper .slogan {
    letter-spacing: calc((1.375rem + 1.75
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
    font-size: 1.375rem;
    text-align: right;
  }
}
@media screen and (min-width: 35em) and (min-width: 20rem) {
  .slogan_wrapper .slogan {
    font-size: calc(1.375rem + 1.75
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 35em) and (min-width: 93.75em) {
  .slogan_wrapper .slogan {
    font-size: 3.125rem;
  }
}
@media screen and (min-width: 35em) {
  .welcome_text {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center;
    padding: 6em 0.75em 3em;
    position: relative;
  }
  .welcome_text p {
    max-width: 46.25rem;
  }
  .welcome_text::before {
    background: url("../images/bogen_unten.svg");
    background-position: left top;
    background-repeat: no-repeat;
    background-size: auto;
    content: "";
    height: 100%;
    left: 0;
    margin: 0 auto;
    max-height: 9.375rem;
    max-width: 120rem;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10;
  }
}
@media screen and (min-width: 35em) {
  .main_wrapper {
    padding-bottom: 6em;
    position: relative;
  }
  .main_wrapper::before {
    background: url("../images/bogen_oben_white.svg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: auto auto;
    content: "";
    height: 100%;
    left: 0;
    max-height: 5rem;
    position: absolute;
    right: 0;
    -webkit-transform: translateY(-99.5%);
            transform: translateY(-99.5%);
    z-index: 100;
  }
}
@media screen and (min-width: 61.875em) {
  .main_wrapper .left_content {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .main_wrapper .right_content {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .main_wrapper:after {
    background: #ffffff;
    bottom: 0;
    content: "";
    position: absolute;
    right: 100%;
    top: 0;
    width: 100%;
    z-index: 10;
  }
}
@media screen and (min-width: 61.875em) {
  .sub_menu {
    margin-top: 6em;
  }
  .sub_menu ul {
    text-align: left;
  }
  .sub_menu::before {
    display: none;
  }
  .sub_menu::after {
    background: url("../images/sub_menu.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    bottom: -6em;
    content: "";
    left: -150%;
    position: absolute;
    right: 0;
    top: -4.5em;
    z-index: -1;
  }
}
@media screen and (min-width: 75em) {
  .sub_menu::after {
    left: -120%;
  }
}
@media screen and (min-width: 35em) {
  .quote {
    padding: 3em 0 6em;
  }
}
@media screen and (min-width: 35em) {
  .footer_wrapper {
    position: relative;
  }
  .footer_wrapper::before {
    background: url("../images/bogen_oben.svg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: auto auto;
    content: "";
    height: 100%;
    left: 0;
    max-height: 5rem;
    position: absolute;
    right: 0;
    -webkit-transform: translateY(-99.5%);
            transform: translateY(-99.5%);
  }
}
@media screen and (min-width: 61.875em) {
  .dropdown_menu {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
  }
}
@media screen and (min-width: 35em) {
  .google_maps .step:before {
    font-size: inherit;
  }
}
@media screen and (min-width: 35em) {
  .google_maps .instructions,
  .google_maps .distance {
    font-size: inherit;
  }
}
@media screen and (min-width: 61.875em) {
  .toggle_offcanvas_menu {
    display: none;
  }
}
@media screen and (min-width: 35em) {
  .toggle_offcanvas_menu label .text {
    display: block;
  }
}
@media screen and (min-width: 61.875em) {
  .offcanvas_menu_wrapper {
    overflow-x: inherit;
  }
}
@media screen and (max-width: 61.8125em) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .page,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .page,
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    opacity: 1;
    -webkit-transform: translate(15.625rem, 0);
            transform: translate(15.625rem, 0);
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_content,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_content {
    opacity: 1;
    -webkit-transition: none;
    transition: none;
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay {
    opacity: 1;
    visibility: visible;
  }
}
@media screen and (max-width: 61.8125em) and (min-width: 30em) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .page,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .page,
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    -webkit-transform: translate(21.875rem, 0);
            transform: translate(21.875rem, 0);
  }
}
@media screen and (min-width: 30em) {
  .offcanvas_menu {
    -webkit-box-flex: 1;
            flex: 1 0 21.875rem;
  }
}
@media screen and (min-width: 61.875em) {
  .teaser_overview {
    opacity: 0;
  }
  .teaser_overview.animated {
    -webkit-animation: fadeInDown 1000ms;
            animation: fadeInDown 1000ms;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }
}
@media screen and (min-width: 20rem) {
  .teaser_overview .teaser .title {
    font-size: calc(1.375rem + 0.125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  .teaser_overview .teaser .title {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 93.75em) {
  .team_overview .entry {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
  }
}
@media print {
  .hideme_print,
  .cms_live_management {
    display: none !important;
  }
  .showme_print {
    display: inherit !important;
    height: auto !important;
    max-height: none !important;
  }
  * {
    background: transparent !important;
    border-color: black !important;
    color: black !important;
    text-shadow: none !important;
  }
  *:before {
    color: black !important;
  }
  @page {
    margin: 2cm;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }
  main a {
    text-decoration: underline;
  }
  main a.touch_link {
    margin-bottom: 1.5em;
    page-break-inside: avoid;
  }
  .table {
    overflow-y: inherit;
  }
  tr {
    page-break-inside: avoid;
  }
  dl {
    margin: 0;
  }
  dt {
    font-weight: 700;
    margin-bottom: 0.75em;
  }
  dd {
    margin: 0;
  }
  [role="tooltip"] {
    display: none;
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  body {
    display: block;
    overflow-x: visible;
  }
  .page {
    display: block;
  }
  main {
    padding-top: 1.5em;
  }
  .company_logo {
    display: block;
    width: 16.25rem;
  }
  .company_logo img {
    width: 100%;
  }
  ul {
    list-style: circle;
    margin: 0 0 0.75em;
    padding-left: 1.125em;
  }
  ul > li {
    padding-left: 0.375em;
    position: relative;
  }
  ul > li::before {
    display: none;
  }
  .contact_wrapper p > span {
    display: block;
  }
  .qr_code_print {
    border-top: 0.0625rem solid;
    margin: 1.5em 0 0;
    padding: 0.75em 0 0;
    page-break-inside: avoid;
  }
  .qr_code_print:after {
    clear: both;
  }
  .qr_code_print:after,
  .qr_code_print:before {
    content: "";
    display: table;
  }
  .qr_code_print img {
    float: left;
  }
  .qr_code_print p {
    margin-left: 10rem;
  }
}
